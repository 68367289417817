import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeviceBoDtoNBK, DeviceService, UserKeycloakDtoNBK } from '../api/nbk';
import { getUrlRedirectPopupDevice, KEY_LANGUAGE_STORAGE } from '../core/utils';
import { PairDeviceComponent } from '../shared/modals/pair-device/pair-device.component';
import { ActivatedRoute } from '@angular/router';
import { InfoDeviceEnum, IPairDevice, showDeviceInfo } from '../core/device.utils';
import { DeviceFiltersService } from './device-filters/device-filters.service';
import { TableColumn } from '@swimlane/ngx-datatable';
import { DictionaryService } from '../core/dictionary.service';

type DeviceTableColumn = TableColumn & {
  infoDeviceEnum?: InfoDeviceEnum
}

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  kcUser: UserKeycloakDtoNBK;
  dataSource = new ODataFiltrableDataSource<DeviceBoDtoNBK>();

  showGrid: boolean = true;
  columns: DeviceTableColumn[] = [];

  @ViewChild('onlineTpl', { static: true }) 
  onlineTpl: ElementRef<HTMLElement>;

  @ViewChild('deviceNameTpl', { static: true }) 
  deviceNameTpl: ElementRef<HTMLElement>;

  @ViewChild('hardwareIdTpl', { static: true }) 
  hardwareIdTpl: ElementRef<HTMLElement>;

  @ViewChild('serialTpl', { static: true }) 
  serialTpl: ElementRef<HTMLElement>;

  @ViewChild('firmwareTpl', { static: true }) 
  firmwareTpl: ElementRef<HTMLElement>;

  @ViewChild('deviceModelTpl', { static: true }) 
  deviceModelTpl: ElementRef<HTMLElement>;

  @ViewChild('displayTpl', { static: true }) 
  displayTpl: ElementRef<HTMLElement>;

  @ViewChild('lastSyncTpl', { static: true }) 
  lastSyncTpl: ElementRef<HTMLElement>;

  @ViewChild('firstAssociationTpl', { static: true }) 
  firstAssociationTpl: ElementRef<HTMLElement>;
  
  @ViewChild('actionTpl', { static: true }) 
  actionTpl: ElementRef<HTMLElement>;


  loadDevices$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();

  lang: string;
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private modalService: NgbModal,
    private dictionary: DictionaryService,
    private ts: TranslateService,
    private toastrService: ToastrService,
    private deviceFiltersService: DeviceFiltersService,
  ) {
    this.dictionary.lang$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((lang) => {
          this.lang = lang;
        })
      )
      .subscribe();

    this.kcUser = this.route.snapshot.data.deviceData.loggedUser;
    //TODO: filter on name, serial and model
    this.dataSource.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.deviceService.getAllDevicesWIthDisplays(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
        top,
        skip,
        count,
        _orderBy,
        filter
      );
    };

    this.dataSource.limit = 8;
  }

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'online',
        name: '',
        sortable: false,
        resizeable: false,
        maxWidth:50,
        cellTemplate: this.onlineTpl
      },
      {
        prop: 'name',
        name: this.ts.instant('DEVICES.DEVICE_CARD.NAME'),
        cellTemplate: this.deviceNameTpl,
        infoDeviceEnum: InfoDeviceEnum.name
      },
      {
        prop: 'hardwareId',
        name: this.ts.instant('DEVICES.DEVICE_CARD.HARDWARE_ID'),
        cellTemplate: this.hardwareIdTpl,
        infoDeviceEnum: InfoDeviceEnum.hardware
      },
      {
        prop: 'serial',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SERIAL_NUMBER'),
        cellTemplate: this.serialTpl,
        infoDeviceEnum: InfoDeviceEnum.serial
      },
      {
        prop: 'firmware',
        name: this.ts.instant('DEVICES.DEVICE_CARD.FIRMWARE'),
        sortable: false,
        cellTemplate: this.firmwareTpl,
        infoDeviceEnum: InfoDeviceEnum.firmware
      },
      {
        prop: 'model',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODEL'),
        cellTemplate: this.deviceModelTpl,
        infoDeviceEnum: InfoDeviceEnum.model
      },
      {
        prop: 'displays',
        name: this.ts.instant('MY_DISPLAY.DISPLAY'),
        sortable: false,
        cellTemplate: this.displayTpl,
        infoDeviceEnum: InfoDeviceEnum.display
      },
      {
        prop: 'lastSync',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SINC'),
        cellTemplate: this.lastSyncTpl,
        infoDeviceEnum: InfoDeviceEnum.sync
      },
      {
        prop: 'currentOwnerFirstAssociation',
        name: this.ts.instant('DEVICES.DEVICE_CARD.REGISTRATION_DATE'),
        sortable: false,
        cellTemplate: this.firstAssociationTpl,
        infoDeviceEnum: InfoDeviceEnum.date
      },
      {
        prop: '',
        name: '',
        sortable: false,
        resizeable: false,
        maxWidth:50,
        cellTemplate: this.actionTpl
      },
    ];

    this.hideColumn();

    this.loadDevices$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.dataSource.loadData())
      )
      .subscribe();

    this.filtered();
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
    this.unsubscribe$.next();
  }

  hideColumn() {
    this.columns = this.columns.filter(column => column.infoDeviceEnum ? showDeviceInfo(column.infoDeviceEnum) : true);
  }

  loadDataSource() {
    this.loadDevices$.next();
  }

  cleanedFilter() {
    this.deviceFiltersService.resetFilter( this.dataSource );
    this.dataSource.applyFilters();
  }

  filtered(val?: any) {
    this.deviceFiltersService.setCustomFilter(
      this.dataSource,
    );
    this.dataSource.applyFilters();
  }

  toggleView() {
    this.showGrid = !this.showGrid;
  }

  pairDevice(): void {
    const modalRef = this.modalService.open(PairDeviceComponent, {
      backdrop: 'static'
    });

    modalRef.componentInstance.generateSecureCode
      .pipe(
        take(1),
        switchMap((serialNumber: string) =>
          this.deviceService.generateSecureCode(serialNumber)
        ),
        tap(() => (modalRef.componentInstance.modalWizard = 'SECURE_CODE'))
      )
      .subscribe();

    modalRef.componentInstance.pairDevice
      .pipe(
        take(1),
        switchMap((device: IPairDevice) =>
          this.deviceService.addCurrentUserToDevice(
            device.serialNumber,
            device.secureCode
          )
        ),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('DEVICE.DEVICE_PAIRED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        finalize(() => {
          modalRef.close();
          this.loadDataSource();

          setTimeout(() => {
            const {
              keycloack: { baseUrl, realm },
              origin
            } = environment;
            const url = getUrlRedirectPopupDevice(baseUrl, realm, origin);
            window.open(url);
          }, 500);
        })
      )
      .subscribe();
  }

}
