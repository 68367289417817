import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  getDeviceModelList,
  SelectableCookingMode,
  SelectableDeviceModel
} from 'src/app/core/utils';
import { DeviceFiltersService } from './device-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/core/dictionary.service';

@Component({
  selector: 'app-device-filters',
  templateUrl: './device-filters.component.html',
  styleUrls: ['./device-filters.component.scss']
})
export class DeviceFiltersComponent implements OnInit, OnDestroy {
  deviceFilterForm: UntypedFormGroup;
  deviceModelList: SelectableDeviceModel[];

  unsubscribe$ = new Subject<void>();

  @Output() filterChanged = new EventEmitter();
  @Output() filterCleaned = new EventEmitter();

  constructor(
    private ts: TranslateService,
    private ds: DictionaryService,
    private filtersService: DeviceFiltersService,
  ) {
    this.deviceFilterForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl(''),
        serial: new UntypedFormControl(''),
        //TODO to remove when enabled other device management
        deviceModel: new UntypedFormControl('ORACLE'),
        online: new UntypedFormControl(''),
      }
    );

    this.deviceModelList = getDeviceModelList();

  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search() {
    this.filtersService.setFilterForm(this.deviceFilterForm.value);
    this.filterChanged.emit(this.deviceFilterForm.value);
  }

  clean() {
    this.deviceFilterForm.reset();
    this.filterCleaned.emit();
    //TODO da rimuovere quando ci saranno altri device
    this.deviceFilterForm.get('deviceModel')?.patchValue('ORACLE')
  }


}
