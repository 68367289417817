import { Injectable } from '@angular/core';
import {
  contains,
  equal,
  ODataFiltrableDataSource
} from 'filtrable-data-source';
import { DeviceDtoNBK } from '../../api/nbk';

interface filterAction {
  [key: string]: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceFiltersService {
  filterFormValue: any;

  constructor() { }

  setFilterForm(form: any) {
    this.filterFormValue = form;
  }

  setCustomFilter(
    deviceList: ODataFiltrableDataSource<DeviceDtoNBK>
  ) {
    const setFilter: filterAction = {
      name: () => {
        deviceList.setFilter(
          'name', 
          contains(this.filterFormValue.name));
      },
      serial: () => {
        deviceList.setFilter(
          'serial', 
          contains(this.filterFormValue.serial));
      },
      deviceModel: () => {
        deviceList.setFilter(
          'model', 
          equal(this.filterFormValue.deviceModel));
      },
      online: () => {
        deviceList.setFilter(
          'online', 
          equal(true));
      }
    };

    this.resetFilter(deviceList);

    for (const field in this.filterFormValue) {
      if (this.filterFormValue[field]) {
        setFilter[field]();
      }
    }
  }

  resetFilter(
    deviceList: ODataFiltrableDataSource<DeviceDtoNBK>
  ) {
    deviceList.removeFilter('name');
    deviceList.removeFilter('serial');
    deviceList.removeFilter('model');
    deviceList.removeFilter('online');
    deviceList.removeCustomFilter();
  }

}
